import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '@/views/Layout/MainLayout.vue'
import AuthLayout from '@/views/Pages/AuthLayout.vue'
import ScanLayout from '@/views/Pages/ScanLayout.vue'
import NotFound from '@/views/NotFoundPage.vue'

// Custom JWT decode function (client-side only; does not validate signature)
function decodeJwt(token) {
  try {
    const payload = token.split('.')[1]
    // Replace URL-safe characters and pad with '=' if necessary
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/')
    const padded = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=')
    const jsonPayload = decodeURIComponent(
      atob(padded)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    )
    return JSON.parse(jsonPayload)
  } catch (e) {
    console.error('Error decoding JWT', e)
    return null
  }
}

// Create a wrapper for NotFound that checks for codes
const CodeCheckWrapper = {
  name: 'CodeCheckWrapper',
  beforeRouteEnter(to, from, next) {
    console.log('CodeCheckWrapper beforeRouteEnter:', to.fullPath);
    
    // Extract the path from the URL
    const path = to.params.pathMatch;
    let possibleCode;
    
    if (Array.isArray(path)) {
      possibleCode = path[0];
    } else {
      possibleCode = path;
    }
    
    console.log('Checking possible code:', possibleCode);
    
    // Check if it matches our code pattern (4 alphanumeric characters)
    if (possibleCode && /^[A-Za-z0-9]{4}$/.test(possibleCode)) {
      console.log('Valid code detected in wrapper:', possibleCode);
      
      // Redirect based on auth status
      const token = localStorage.getItem('token');
      if (token) {
        next({ name: 'RegisteredCode', params: { code: possibleCode } });
      } else {
        next({ name: 'code', params: { code: possibleCode } });
      }
    } else {
      // Not a code, proceed to NotFound
      next(vm => {
        // Replace the component instance with NotFound
        vm.$parent.ViewComponent = NotFound;
      });
    }
  },
  render() {
    // This component doesn't render anything itself
    return this.$slots.default ? this.$slots.default() : null;
  }
};

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    component: ScanLayout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/Stiqrs/HomePage.vue')
      },
      {
        path: 'scan',
        name: 'scan',
        meta: { requiresGuest: true },
        component: () => import('../views/Stiqrs/FreeScan.vue')
      },
      {
        path: 'code/:code',
        name: 'code',
        meta: { requiresGuest: true },
        component: () => import('../views/Stiqrs/CodePage.vue')
      },
      {
        path: 'public/:code',
        name: 'publicCode',
        component: () => import('../views/Stiqrs/PublicCodePage.vue')
      },
      {
        path: 'aboutus',
        name: 'aboutus',
        meta: { requiresGuest: true },
        component: () => import('../views/Stiqrs/AboutUs.vue')
      },
      {
        path: 'contactus',
        name: 'contactus',
        component: () => import('../views/Stiqrs/ContactUs.vue')
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import('../views/Stiqrs/FAQ.vue')
      }
    ]
  },
  {
    path: '/login',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'login',
        meta: { requiresGuest: true },
        component: () => import('../views/Pages/LoginPage.vue')
      }
    ]
  },
  {
    path: '/admin',
    redirect: '/admin/dashboard',
    component: MainLayout,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: { requiresAuth: true },
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: 'scanadv',
        name: 'scanadv',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/ScanAdv.vue')
      },
      // This route is for code registration for authenticated users.
      {
        path: 'code/:code',
        name: 'RegisteredCode',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/RegisteredCodePage.vue')
      },
      {
        path: 'public/:code',
        name: 'AdminPublicCode',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/PublicCodePage.vue')
      },
      {
        path: 'generateqr',
        name: 'generateqr',
        meta: { requiresAuth: true, requiresAdmin: true },
        component: () => import('../views/Stiqrs/GenerateQr.vue')
      },
      {
        path: 'mystiqrsadmin',
        name: 'mystiqrsadmin',
        meta: { requiresAuth: true, requiresAdmin: true },
        component: () => import('../views/Stiqrs/MystiqrsAdmin.vue')
      },
      {
        path: 'printqr',
        name: 'printqr',
        meta: { requiresAuth: true, requiresAdmin: true },
        component: () => import('../views/Stiqrs/PrintQr.vue')
      },
      {
        path: 'useradmin',
        name: 'useradmin',
        meta: { requiresAuth: true, requiresAdmin: true },
        component: () => import('../views/Pages/UserAdmin.vue')
      },
      {
        path: 'logs',
        name: 'activity-logs',
        meta: { requiresAuth: true, requiresAdmin: true },
        component: () => import('../views/Admin/ActivityLogs.vue')
      },
      {
        path: 'mystiqrs',
        name: 'mystiqrs',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/MyStiqrs.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        meta: { requiresAuth: true },
        component: () => import('../views/Pages/UserProfile.vue')
      },
      {
        path: 'faqadmin',
        name: 'faqadmin',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/FAQ.vue')
      },
      {
        path: 'newuser',
        name: 'newuser',
        meta: { requiresAuth: true },
        component: () => import('../views/Pages/newUser.vue')
      }
    ]
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: CodeCheckWrapper }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Combined global navigation guard.
router.beforeEach((to, from, next) => {
  console.log('Router navigation:', to.fullPath, 'Route name:', to.name);
  console.log('Route params:', JSON.stringify(to.params));
  
  // Check if the path might be a direct code
  if (to.name === 'NotFound' && to.params.pathMatch) {
    console.log('NotFound route detected, pathMatch:', to.params.pathMatch);
    
    let possibleCode;
    if (Array.isArray(to.params.pathMatch)) {
      possibleCode = to.params.pathMatch[0];
      console.log('pathMatch is array, first element:', possibleCode);
    } else {
      possibleCode = to.params.pathMatch;
      console.log('pathMatch is string:', possibleCode);
    }
    
    // Check if it matches our code pattern (4 alphanumeric characters)
    if (possibleCode && /^[A-Za-z0-9]{4}$/.test(possibleCode)) {
      console.log('Detected direct code access:', possibleCode);
      
      // Redirect to the appropriate code page based on auth status
      const token = localStorage.getItem('token');
      if (token) {
        // If logged in, go to admin code page
        console.log('User is logged in, redirecting to RegisteredCode');
        return next({ name: 'RegisteredCode', params: { code: possibleCode } });
      } else {
        // If not logged in, go to public code page
        console.log('User is not logged in, redirecting to code');
        return next({ name: 'code', params: { code: possibleCode } });
      }
    } else {
      console.log('Not a valid code pattern:', possibleCode);
    }
  }
  
  // 1. If the route requires authentication, check for token.
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem('token');
    if (!token) {
      return next({ name: 'login' });
    }
    const decoded = decodeJwt(token);
    if (!decoded) {
      return next({ name: 'login' });
    }
    if (to.meta.requiresAdmin && parseInt(decoded.user_role, 10) !== 3) {
      return next({ name: 'home' });
    }
  }

  // 2. Check for a pending code flag only if the user is logged in.
  const token = localStorage.getItem('token');
  if (token) {
    const pendingCode = localStorage.getItem('pendingCode');
    if (pendingCode) {
      // If already on the RegisteredCode route with the same code, clear the flag.
      if (to.name === 'RegisteredCode' && to.params.code === pendingCode) {
        localStorage.removeItem('pendingCode');
        return next();
      }
      // Otherwise, force redirect to RegisteredCode.
      return next({ name: 'RegisteredCode', params: { code: pendingCode } });
    }
  }
  
  next();
});

export default router;
