<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    // No need for token expiration checks here; rely on router guards and Axios interceptors
    console.log('App mounted');
  },
};
</script>
