<template>
  <div class="wrapper">
    <side-menu></side-menu>
    <div class="main-content">
      <top-menu></top-menu>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>
  </div>
  <div class="content-footer">
    <content-footer v-if="!$route.meta.hideFooter"></content-footer>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu/SideMenu.vue'; // Ensure this path is correct
import TopMenu from './TopMenu.vue';
import ContentFooter from './ContentFooter.vue';

export default {
  components: {
    SideMenu,
    TopMenu,
    ContentFooter,
  },
};
</script>

<style lang="scss">
.wrapper {
  display: flex;
  min-height: 100vh;
}

/* Apply padding only on larger screens (desktop) */
@media (min-width: 769px) {
  .main-content {
    flex: 1;
    background-color: var(--color-background);
    padding-left: 350px;
    /* Desktop padding */
  }
}

/* No padding on smaller screens (mobile) */
@media (max-width: 768px) {
  .main-content {
    flex: 1;
    background-color: var(--color-background);
    padding-left: 0;
    /* Mobile */
  }
}

.content-footer {
  background-color: #3f4066;
  bottom: 0;
  width: 100%;
}
</style>
