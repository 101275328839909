<template>
  <div>
    <!-- Hamburger Icon -->
    <input type="checkbox" id="menu-toggle" class="menu-toggle" v-model="isMenuOpen">
    <label for="menu-toggle" class="hamburger" :class="{ 'is-active': isMenuOpen }">
      <span></span>
      <span></span>
      <span></span>
    </label>

    <!-- Sidebar -->
    <nav class="sidemenu">
      <div class="topMenu-wrapper">
        <router-link to="/" class="topMenu-brand">
          <img style="height: 100px;" src="@/assets/logo.svg" alt="Logo" />
        </router-link>
      </div>
      <ul class="inner-menu">
        <li v-for="item in filteredMenuItems" :key="item.name">
          <router-link 
            :to="item.path" 
            @click="closeMenu"
            active-class="active-link"
            exact-active-class="active-link"
          >
            <img :src="item.icon" class="menu-icon" :alt="item.name" /> {{ item.name }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
// Custom JWT decode function (client-side; no signature validation)
function decodeJwt(token) {
  try {
    const payload = token.split('.')[1];
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    const padded = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');
    const jsonPayload = decodeURIComponent(
      atob(padded)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error('Error decoding JWT:', e);
    return null;
  }
}

// Import icons
import homeIcon from '@/assets/icons/feather/home.svg';
import cameraIcon from '@/assets/icons/feather/camera.svg';
import listIcon from '@/assets/icons/feather/list.svg';
import clipboardIcon from '@/assets/icons/feather/clipboard.svg';
import plusSquareIcon from '@/assets/icons/feather/plus-square.svg';
import printerIcon from '@/assets/icons/feather/printer.svg';
import helpCircleIcon from '@/assets/icons/feather/help-circle.svg';
import usersIcon from '@/assets/icons/feather/users.svg';
import activityIcon from '@/assets/icons/feather/activity.svg';

export default {
  name: 'SideMenu',
  data() {
    return {
      isMenuOpen: false
    }
  },
  props: {
    menuItems: {
      type: Array,
      default: () => [
        { name: 'Dashboard', path: '/admin/dashboard', icon: homeIcon },
        { name: 'Scan StiQR', path: '/admin/scanadv', icon: cameraIcon },
        { name: 'My StiQRs', path: '/admin/mystiqrs', icon: listIcon },
        { name: 'My StiQRs Admin', path: '/admin/mystiqrsAdmin', icon: clipboardIcon, adminOnly: true },
        { name: 'Generate StiQR', path: '/admin/generateqr', icon: plusSquareIcon, adminOnly: true },
        { name: 'Print StiQR', path: '/admin/printqr', icon: printerIcon, adminOnly: true },
        { name: 'FAQ', path: '/admin/faqadmin', icon: helpCircleIcon },
        { name: 'User Admin', path: '/admin/useradmin', icon: usersIcon, adminOnly: true },
        { name: 'Activity Logs', path: '/admin/logs', icon: activityIcon, adminOnly: true }
      ]
    }
  },
  methods: {
    closeMenu() {
      this.isMenuOpen = false;
    }
  },
  computed: {
    isAdmin() {
      const token = localStorage.getItem('token');
      if (!token) return false;
      const decoded = decodeJwt(token);
      return decoded && parseInt(decoded.user_role, 10) === 3;
    },
    filteredMenuItems() {
      return this.menuItems.filter(item => {
        if (item.adminOnly) {
          return this.isAdmin;
        }
        return true;
      });
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
.admin-users-page .header {
  padding-top: 60px;
  padding-bottom: 30px;
}
.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.bulk-action {
  display: flex;
  gap: 15px;
  align-items: center;
}
.search-input {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message {
  color: green;
  margin-bottom: 20px;
}

.mt-3 {
  margin: 10px;
}

.dropdown-list {
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  display: none;
}

.dropdown-list.show {
  display: block;
}

.dropdown-item {
  padding: 10px;
  color: #333;
}

.selected-row {
  background-color: #eaf4ff !important;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.paginate-buttons,
.items-per-page-select {
  background-color: #87cafc;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 5px 15px;
}

.btn-go-code {
  background-color: #87cafc;
  border: none;
  border-radius: 50px;
  color: #3f4066;
}

.sidemenu {
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 20px 15px;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  height: 100vh;
  width: 300px;
  box-sizing: border-box;
  position: fixed;
}

.inner-menu {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: grid;
  justify-content: start;
  width: 100%;
  overflow: hidden;
  max-width: 300px;
  box-sizing: border-box;
  padding-left: 35px;
}

.logo-img {
  height: 50px;
}

.inner-menu li {
  margin-bottom: 8px;
  width: 100%;
  position: relative;
  min-height: 44px;
}

.inner-menu a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #3f4066;
  font-weight: bold;
  transition: all 0.3s ease;
  padding: 12px 15px;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0;
  font-size: 15px;
  box-sizing: border-box;
}

.inner-menu a:hover {
  color: #87cafc;
  background-color: rgba(135, 202, 252, 0.1);
}

.inner-menu a.active-link {
  color: #3f4066;
  background-color: rgba(135, 202, 252, 0.15);
  font-weight: 700;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 12px 15px;
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: 0.3px;
  box-sizing: border-box;
}

.inner-menu a.active-link::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background-color: #3f4066;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.inner-menu a.active-link .menu-icon {
  transform: scale(1.2);
  margin-right: 12px;
}

.menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  transition: all 0.3s ease;
}

/* Updated hamburger styles */
.hamburger {
  cursor: pointer;
  display: none;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
  z-index: 102;
  position: fixed;
  top: 10px;
  left: 15px;
  width: 30px;
  height: 30px;
  transition: all 0.3s ease;
}

.hamburger span {
  background-color: #3f4066;
  border-radius: 2px;
  height: 3px;
  width: 30px;
  transition: all 0.3s ease;
  position: absolute;
}

.hamburger span:nth-child(1) {
  top: 15px;
}

.hamburger span:nth-child(2) {
  top: 23px;
}

.hamburger span:nth-child(3) {
  top: 31px;
}

/* X animation when menu is open */
.hamburger.is-active span:nth-child(1) {
  transform: rotate(45deg);
  top: 23px;
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active span:nth-child(3) {
  transform: rotate(-45deg);
  top: 23px;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }
  .sidemenu {
    position: fixed;
    top: 0;
    left: -350px;
    height: 100%;
    background-color: white;
    padding: 20px 15px;
    transition: left 0.3s ease;
    z-index: 100;
  }
  .menu-toggle:checked + .hamburger + .sidemenu {
    left: 0;
  }
  .main-content {
    padding-left: 0;
  }
}

@media (min-width: 769px) {
  .hamburger {
    display: none;
  }
  .sidemenu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px;
  }
}
</style>
