<template>
  <div class="scan-content">
    <!-- Navigation bar -->
    <nav class="topMenu-auth topMenu-horizontal justify-content-between" expand="lg">
      <div class="topMenu-wrapper">
        <router-link to="/" class="topMenu-brand">
          <img style="height: 100px;" src="@/assets/logo.svg" alt="Logo" />
          <!-- Floating Squares Background under the logo -->
          <div class="background"></div>
        </router-link>
      </div>
      <ul class="topMenu-nav align-items-lg-center">
        <li class="nav-item">
          <router-link to="/home" class="nav-link-auth">Home</router-link>
        </li>
      </ul>
    </nav>

    <div class="content-container">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>

    <footer class="footer">
      <div class="footer-content">
        <div class="copyright footer-link">
          © {{ year }} <a href="https://www.stiqr.com.au" target="_blank">Stiqr</a>
        </div>
        <ul class="nav-footer">
          <li class="footer-link">
            <a href="https://www.stiqr.com.au/about" target="_blank">About Us</a>
          </li>
          <li class="footer-link">
            <a href="https://www.stiqr.com.au/termsandconditions" target="_blank">Terms & Conditions</a>
          </li>
          <li class="footer-link">
            <a href="https://www.stiqr.com.au/contact" target="_blank">Contact Us</a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      shakeInterval: null,
      animateRequest: null, // Stores animation frame ID
    };
  },
  methods: {
    toggleTopMenu() {
      document.body.classList.toggle('menu-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('menu-open');
      this.showMenu = false;
    },
    startShake() {
      const logoEl = this.$el.querySelector(".topMenu-brand");
      this.shakeInterval = setInterval(() => {
        const newDx = Math.random() * 20 - 10;
        logoEl.style.transform = `translate(${newDx}px, 0)`;
      }, 80);
      setTimeout(() => {
        clearInterval(this.shakeInterval);
        logoEl.style.transform = '';
        logoEl.style.overflow = 'visible';
      }, 5000);
    },
    createFloatingSquares() {
      const background = this.$el.querySelector(".background");
      background.innerHTML = "";
      const columns = 8, rows = 8, squareSize = 10, spacing = 1;
      const logoEl = this.$el.querySelector(".topMenu-brand");
      logoEl.style.position = "relative";
      logoEl.style.overflow = "hidden";
      const logoWidth = logoEl.offsetWidth, logoHeight = logoEl.offsetHeight;
      const offsetX = (logoWidth - columns * (squareSize + spacing)) / 5;
      const offsetY = (logoHeight - rows * (squareSize + spacing)) / 2;

      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {
          const square = document.createElement("div");
          square.classList.add("square");
          square.style.left = `${offsetX + col * (squareSize + spacing)}px`;
          square.style.top = `${offsetY + row * (squareSize + spacing)}px`;
          square.style.width = `${squareSize}px`;
          square.style.height = `${squareSize}px`;
          background.appendChild(square);
          setTimeout(() => this.releaseSquare(square), Math.random() * 5000 + 500);
        }
      }
    },
    releaseSquare(square) {
      if (square.parentNode && square.parentNode.classList.contains("background")) {
        const rect = square.getBoundingClientRect();
        square.parentNode.removeChild(square);
        document.body.appendChild(square);
        square.style.left = `${rect.left}px`;
        square.style.top = `${rect.top}px`;
        square.style.position = "absolute";
        square.dataset.dx = Math.random() * 3 - 1;
        square.dataset.dy = Math.random() * 3 - 1;
      }
    },
    animateSquares() {
      const squares = document.querySelectorAll(".square");
      squares.forEach(square => {
        let x = parseFloat(square.style.left);
        let y = parseFloat(square.style.top);
        let dx = parseFloat(square.dataset.dx);
        let dy = parseFloat(square.dataset.dy);
        x += dx;
        y += dy;
        if (x <= 0 || x >= window.innerWidth - square.offsetWidth) dx *= -0.9;
        if (y <= 0 || y >= window.innerHeight - square.offsetHeight) dy *= -0.9;
        square.style.left = `${x}px`;
        square.style.top = `${y}px`;
        square.dataset.dx = dx.toString();
        square.dataset.dy = dy.toString();
      });
      this.animateRequest = requestAnimationFrame(this.animateSquares);
    },
    destroyFloatingSquares() {
      
      clearInterval(this.shakeInterval); // Stops shake
      cancelAnimationFrame(this.animateRequest); // Stops floating effect
      document.querySelectorAll(".square").forEach(sq => sq.remove()); // Removes squares
    },
  },
  beforeUnmount() {
    this.destroyFloatingSquares();
  },
  beforeRouteUpdate(to, from, next) {
    this.destroyFloatingSquares();
    next();
  },
  mounted() {
    this.createFloatingSquares();
    setTimeout(() => this.startShake(), 500);
    this.animateSquares();
  },
};
</script>

<style lang="scss">
.scan-content {
  position: relative;
  overflow: hidden;
}
.topMenu-brand {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.topMenu-brand img {
  position: relative;
  z-index: 2;
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.square {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  transition: transform 0.1s linear;
}
.content-container,
.footer {
  position: relative;
  z-index: 1;
}
.topMenu-auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20%;
}
.topMenu-nav {
  display: flex;
  align-items: center;
  list-style-type: none;
}
.nav-link-auth {
  color: #3f4066;
  font-weight: bold;
  text-decoration: none;
  z-index: 1;
}
.nav-link-auth:hover {
  color: #87cafc;
}
</style>
