import './assets/main.css'
import './assets/tailwind.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'

import BootstrapVue3 from 'bootstrap-vue-3' // Import BootstrapVue 3
import SideMenuPlugin from './components/SideMenu/SideMenu.vue' // Ensure this path is correct
import VueAwesomePaginate from 'vue-awesome-paginate' // Import Vue Awesome Paginate
import 'vue-awesome-paginate/dist/style.css' // Import Vue Awesome Paginate CSS

import axios from 'axios'

// Create an instance of axios
const api = axios.create({
  baseURL: 'http://localhost:5173/', // Set your base URL
  headers: {
    'Content-Type': 'application/json'
  }
})

// Add a response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      const pendingCode = localStorage.getItem('pendingCode');
      console.log('Axios Interceptor: 401 Unauthorized - Redirecting to login')
      // Clear token and tokenExpiration from localStorage
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiration')
      localStorage.removeItem('mfaRequired')
      if (pendingCode) localStorage.setItem('pendingCode', pendingCode);
      // Redirect to login page with a message
      router.push({
        name: 'login',
        query: { message: 'Session expired. Please log in again.' }
      })
    }
    return Promise.reject(error)
  }
)

// Helper function to validate token expiration and ensure MFA flag is not set
const isTokenValid = () => {
  const token = localStorage.getItem('token')
  const tokenExpiration = localStorage.getItem('tokenExpiration')
  // If the MFA flag is present, treat the token as not valid for general access.
  if (localStorage.getItem('mfaRequired') === 'true') {
    console.warn('isTokenValid: Temporary MFA token in use; token not considered fully valid.')
    return false
  }
  
  // Convert token expiration to number (it's stored in milliseconds)
  const tokenExpirationTime = parseInt(tokenExpiration, 10)
  
  // Current time in milliseconds
  const now = Date.now()
  
  console.log('isTokenValid: Current Time (ms):', now)
  console.log('isTokenValid: Token Expiration (ms):', tokenExpirationTime)
  console.log('isTokenValid: Token Exists:', !!token)
  console.log('isTokenValid: Token Valid:', token && tokenExpirationTime && now < tokenExpirationTime)
  
  return token && tokenExpirationTime && now < tokenExpirationTime
}

// Router navigation guards
router.beforeEach((to, from, next) => {
  console.log('Router Guard: Navigating to', to.path)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isTokenValid()) {
      console.log('Router Guard: Token is valid, allowing navigation')
      next()
    } else {
      console.log('Router Guard: Token expired or invalid, redirecting to login')
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiration')
      localStorage.removeItem('mfaRequired')
      next({
        name: 'login',
        query: { message: 'Session expired. Please log in again.' }
      })
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (isTokenValid()) {
      console.log('Router Guard: User logged in, redirecting to /admin/dashboard')
      next('/admin/dashboard')
    } else {
      console.log('Router Guard: No valid token, proceeding to', to.path)
      next()
    }
  } else {
    console.log('Router Guard: No special conditions, proceeding to', to.path)
    next()
  }
})

const app = createApp(App)

app.use(router)
app.use(BootstrapVue3) // Use BootstrapVue 3
app.use(VueAwesomePaginate) // Use Vue Awesome Paginate

app.mount('#app')

app.use(SideMenuPlugin, { sidemenuLinks: [] }) // Pass necessary options
