<template>
  <nav class="custom-menu">
    <div class="custom-menu-content">
      <!-- Display the current route name as the title on the left side -->
      <div class="custom-menu-left">
        <!-- Search icon or expanded search field based on the screen size -->
        <div class="custom-search-container" @click="toggleSearch">
          <i class="fas fa-search custom-search-icon"></i>
          <input v-show="showSearchField" type="text" placeholder="Search" v-model="searchQuery"
            @keyup.enter="performSearch" />
        </div>
      </div>

      <!-- User profile dropdown menu on the right -->
      <div class="custom-menu-right">
        <div class="custom-profile-dropdown" @click="toggleDropdown">
          <img src="@/assets/icons/feather/user.svg" class="custom-avatar" alt="User" />
          <!-- Hidden on mobile -->
          <span class="custom-username" v-if="!isMobile">{{ fullName }}</span>
        </div>

        <!-- Dropdown menu for user actions -->
        <ul :class="{ 'custom-dropdown-menu': true, 'visible': showDropdown }">
          <li class="custom-dropdown-header">WELCOME!</li>
          <li><router-link to="/admin/profile"><img src="@/assets/icons/feather/user.svg" class="menu-icon" alt="Profile" /> My profile</router-link></li>
          <li><router-link to="/admin/settings"><img src="@/assets/icons/feather/settings.svg" class="menu-icon" alt="Settings" /> Settings</router-link></li>
          <li><router-link to="/admin/activity"><img src="@/assets/icons/feather/activity.svg" class="menu-icon" alt="Activity" /> Activity</router-link></li>
          <li><router-link to="/contactus"><img src="@/assets/icons/feather/help-circle.svg" class="menu-icon" alt="Support" /> Support</router-link></li>
          <li class="custom-dropdown-divider"></li>
          <li><a href="#!" @click="logout"><img src="@/assets/icons/feather/log-out.svg" class="menu-icon" alt="Logout" /> Logout</a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
import { ref, onMounted, onBeforeUnmount } from "vue"; // Import Vue's composition API
import axios from 'axios';

export default {
  name: "TopMenu",
  setup() {
    const showDropdown = ref(false);
    const showSearchField = ref(false); // Controls the visibility of the search input
    const searchQuery = ref("");
    const fullName = ref("");
    const isMobile = ref(false); // Detect if the screen is mobile

    const updateUserName = async () => {
      const authToken = localStorage.getItem('token');
      const userId = localStorage.getItem('active_user_id');
      
      if (authToken && userId) {
        try {
          const url = `https://api2.stiqr.com.au/prod/users/id/${userId}`;
          const config = { headers: { Authorization: authToken } };
          const response = await axios.get(url, config);
          
          if (response.status === 200 && response.data) {
            const data = response.data;
            const name = `${data.first_name || ''} ${data.last_name || ''}`.trim();
            fullName.value = name || 'User';
            console.log('Name set to:', name);
          } else {
            console.error('Failed to fetch user data:', response.status);
            fullName.value = 'User';
          }
        } catch (e) {
          console.error('Error fetching user data:', e);
          fullName.value = 'User';
        }
      } else {
        console.log('Missing token or userId. Token:', authToken, 'UserId:', userId);
        fullName.value = 'User';
      }
    };

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const closeDropdown = (event) => {
      if (!event.target.closest(".custom-profile-dropdown")) {
        showDropdown.value = false;
      }
    };

    const toggleSearch = () => {
      showSearchField.value = !showSearchField.value;
    };

    const performSearch = () => {
      console.log("Searching for:", searchQuery.value);
    };

    const logout = () => {
      localStorage.clear();
      window.location.href = "/login";
    };

    // Detect mobile screen width
    const detectMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      detectMobile();
      updateUserName();
      window.addEventListener("resize", detectMobile); // Update on window resize
      document.addEventListener("click", closeDropdown);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", detectMobile);
      document.removeEventListener("click", closeDropdown);
    });

    return {
      showDropdown,
      showSearchField,
      searchQuery,
      fullName,
      isMobile,
      toggleDropdown,
      toggleSearch,
      performSearch,
      logout,
    };
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return name ? name.charAt(0).toUpperCase() + name.slice(1) : "";
    },
  },
};
</script>

<style scoped>
/* Main menu container */
.custom-menu {
  background-color:  var(--color-background);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

.custom-menu-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.custom-menu-left,
.custom-menu-right {
  display: flex;
  align-items: center;
}

/* Route name styling */
.custom-route-name {
  display: none; /* Hide the route name */
}

/* Search functionality */
.custom-search-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-search-container input {
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  outline: none;
  width: 200px;
}

.custom-search-icon {
  position: absolute;
  right: 10px;
  color: #999;
}

/* Profile dropdown trigger */
.custom-profile-dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 24px;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.1);
}

.custom-profile-dropdown:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.custom-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 12px;
  border: 2px solid #ffffff;
  transition: transform 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 6px;
}

.custom-profile-dropdown:hover .custom-avatar {
  transform: scale(1.05);
}

.custom-username {
  font-size: 14px;
  font-weight: 600;
  color: #3f4066;
  margin-right: 8px;
}

/* Dropdown menu */
.custom-dropdown-menu {
  position: absolute;
  top: 60px;
  right: 10px;
  transform: translateY(-10px);
  width: 200px;
  background-color: #f8fafc;
  border-radius: 12px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  display: block;
  box-sizing: border-box;
}

.custom-dropdown-menu.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Dropdown header */
.custom-dropdown-header {
  background-color: #f8fafc;
  color: #3f4066;
  font-size: 13px;
  font-weight: 700;
  padding: 12px 15px;
  letter-spacing: 0.8px;
  border-bottom: 2px solid #3f4066;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-dropdown-header::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background-color: #87cafc;
}

/* Dropdown menu items */
.custom-dropdown-menu li {
  padding: 0;
  transition: background-color 0.2s ease;
  width: 100%;
  position: relative;
  min-height: 44px;
  box-sizing: border-box;
  text-align: left;
}

.custom-dropdown-menu li a {
  padding: 8px 10px;
  color: #3f4066;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  transition: all 0.3s ease;
  font-weight: 500;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  border-radius: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-dropdown-menu li a:hover {
  color: #87cafc;
  background-color: white;
}

/* Active menu item */
.custom-dropdown-menu li a.router-link-active {
  color: #87cafc;
  background-color: white;
  font-weight: 700;
  position: relative;
  font-size: 15px;
  letter-spacing: 0.3px;
}

.custom-dropdown-menu li a.router-link-active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background-color: #87cafc;
  border-radius: 0;
}

.custom-dropdown-menu li a.router-link-active .menu-icon {
  transform: scale(1.2);
}

/* Divider */
.custom-dropdown-divider {
  height: 1px;
  background-color: #eef2f7;
  margin: 4px 0;
  width: 100%;
}

/* Menu icons */
.menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  transition: all 0.3s ease;
}

/* Mobile styles */
@media (max-width: 768px) {
  .custom-username {
    display: none;
  }

  .custom-search-container input {
    display: none;
  }

  .custom-search-container input:focus {
    display: block;
    width: 150px;
  }

  .custom-dropdown-menu {
    width: 180px;
    right: 10px;
    top: 55px;
  }

  .custom-dropdown-menu li a {
    padding: 10px 12px;
    font-size: 14px;
  }

  .custom-profile-dropdown {
    padding: 4px;
  }

  .custom-avatar {
    width: 32px;
    height: 32px;
    margin-right: 0;
  }
}
</style>
